import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import Papa from 'papaparse';
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

import './index.css';
import { logout, setAddress, } from '../../store/actions/Auth';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
const AdminNavbar = props => {
  const [state, setState] = useState({
    collapseOpen: false,
    modalSearch: false,
    color: "navbar-transparent",
    role: localStorage.getItem('role'),
  })

  const [open, setOpen] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const [rows, setRows] = useState(0)

  const initdata = {
    models: [],
    images: [],
    csv: ''
  }
  const [data, setData] = useState(initdata)

  useEffect(() => {
    if (data.images.length === 0 || data.models.length === 0) return;

    if (rows !== data.images.length || rows !== data.models.length)
      setIsValidated(false)
    else setIsValidated(true)
  }, [data, rows])


  const handleFiles = e => {
    setData(st => ({
      ...st,
      [e.target.name]: e.target.files
    }))
  }

  const handleFile = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target.result;
        const parsedData = Papa.parse(contents, { header: true }).data;

        setRows(parsedData.length)
      };
      reader.readAsText(file);
    }
    setData(st => ({
      ...st,
      [e.target.name]: e.target.files[0]
    }))
  }

  const toggleModalSearch = () => {
    setState({
      modalSearch: !state.modalSearch
    });
  };

  const logout = () => {
    props.logout();
    props.history.push('/login');
  };

  const handleSubmit = e => {
    e.preventDefault()


    const formData = new FormData()
    // Append individual files to the FormData


    // formData.append("images[]", data.images)
    // formData.append("model[]", data.models)

    Array(data.images.length).fill().forEach((modelFile, idx) => {
      formData.append('images', data.images[idx]);
    });
    Array(data.models.length).fill().forEach((modelFile, idx) => {
      formData.append('model', data.models[idx]);
    });

    formData.append('data', data.csv)

    // props.uploadProducts({
    //   formData,
    //   successCallback: () => {
    //     setOpen(false)
    //     setData(initdata)
    //   },
    //   failCallBack: () => { }
    // })

  }

  return (
    <div className="nav-bar">
      <Navbar
        className={classNames("navbar-absolute", state.color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand className="mt-2" href="#" target="_blank">
              {/* <img alt="..." src={require('../../assets/img/icon.png')} style={{ width: '20%'}} /> */}
            </NavbarBrand>
          </div>
          <Box display={'flex'}>
            {/* <div className="search-form">
                <input type="text" placeholder="Search..." />
                <button className="search-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2393 17.6735L13.1756 13.6098C10.0061 16.1079 5.43903 15.7029 2.7585 12.6862C0.0780783 9.66951 0.213117 5.08641 3.06662 2.2328C5.92013 -0.620799 10.5033 -0.75603 13.52 1.92436C16.5368 4.60474 16.9419 9.17191 14.444 12.3414L18.5076 16.4061C18.7711 16.6242 18.8883 16.9728 18.8101 17.3058C18.732 17.6388 18.472 17.8989 18.139 17.977C17.8059 18.0552 17.4573 17.938 17.2393 17.6744V17.6735ZM2.62577 7.62186C2.62577 9.96438 4.02883 12.08 6.18757 12.9918C8.3462 13.9036 10.841 13.4344 12.5209 11.8006C12.5387 11.7801 12.5566 11.7603 12.5746 11.7414C12.5926 11.7225 12.6133 11.7045 12.6331 11.6883C14.4667 9.80425 14.8125 6.92612 13.4775 4.66129C12.1426 2.39647 9.45697 1.30529 6.9206 1.99717C4.38424 2.68905 2.62478 4.99282 2.62467 7.62183L2.62577 7.62186Z" fill="#231916"/>
                  </svg>
                </button>
            </div> */}
            <Collapse navbar isOpen={state.collapseOpen}>
              <Nav className="ml-auto" navbar>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src={require('../../assets/img/icon.png')} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Logout</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li" onClick={logout}>
                      <DropdownItem className="nav-item">Logout</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Box>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={state.modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>

      <Dialog sx={{
        '& .MuiDialog-paper': {
          width: '400px'
        }
      }} open={open} onClose={() => setOpen(false)}>
        {/* <DialogTitle sx={{ color: '#32325d' }}>
          Import Data
        </DialogTitle> */}
        <DialogContent>
          <form onSubmit={handleSubmit} id='form'>

            <Stack marginBottom={2} direction="row" alignItems="center" spacing={2}>
              <Button size='small' variant="contained" component="label" >
                Upload Images
                <input onChange={handleFiles} name='images' hidden accept="image/*" multiple type="file" />

              </Button>
              {data.images.length > 0 && (
                <Typography color="textSecondary">
                  {data.images.length} image(s) uploaded
                </Typography>
              )}
            </Stack>

            <Stack marginBottom={2} direction="row" alignItems="center" spacing={2}>
              <Button size='small' variant="contained" component="label" >
                Upload Models
                <input onChange={handleFiles} name='models' hidden multiple type="file" />

              </Button>
              {data.models.length > 0 && (
                <Typography color="textSecondary">
                  {data.models.length} model(s) uploaded
                </Typography>
              )}
            </Stack>

            <Stack marginBottom={2} direction="row" alignItems="center" spacing={2}>
              <Button size='small' variant="contained" component="label">
                Upload CSV file
                <input onChange={handleFile} accept='text/csv' name='csv' hidden multiple type="file" />

              </Button>
              {data.csv && (
                <Typography color="textSecondary">
                  1 file uploaded
                </Typography>
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button disabled={!isValidated} variant='contained' color='success' type='submit' form='form' startIcon={<UploadIcon />} size='small'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = { logout, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { role, balance, address } = Auth;
  return { role, balance, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);