import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { ToastContainer, toast } from 'react-toastify';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import Login from './views/Login/index.js';
import Admin from './layouts/Admin.jsx';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from './store/PrivateRoute';
import { logout } from './store/actions/Auth';

import './App.css';

const hist = createBrowserHistory();

const App = (props) => {
  useEffect(() => {
    EventBus.on('info', e => toast.info(e));
    EventBus.on('error', e => toast.error(e));
    EventBus.on('error-login', e => {
      toast.error(e);
    });
    EventBus.on('success', e => toast.success(e));
    EventBus.on('tokenExpired', () => {
      props.logout();
    });
  }, []);

  return (
    <div>
      <ToastContainer />
      <Router history={hist}>
        <Switch>
            <Route path="/login" render={props => <Login {...props} />} />
            <PrivateRoute path="/home" component={props => <Admin {...props} />} />
            <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    </div>
  );
};

const mapDispatchToProps = {
  logout
};

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);