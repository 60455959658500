import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import React, { Fragment,useState,useEffect } from 'react';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import EventBus from "eventing-bus";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {setVoting,getAllVoting,updateVoting, setLoader} from "../../store/actions/Auth";


import './index.css';

const AddNewVoting = props => {
  const [state, setState] = useState({exchanges: [{ 
    image:"",
    previewImage:"",
    title:"",
    description:""
  },{ 
    image:"",
    previewImage:"",
    title:"",
    description:""
  }]});

  const [status, setStatus] = useState("Active");
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()

  const handleSubmit = async () => {
      let {exchanges} = state;
      if(!title) return EventBus.publish('error', "Please set title");
      if(!description) return EventBus.publish('error', "Please set description");

      for (let index = 0; index < exchanges.length; index++) {
        const element = exchanges[index];
        if(!element['previewImage']) return EventBus.publish('error', "Please upload image");
        if(!element['title']) return EventBus.publish('error', "Please set item title");
        if(!element['description']) return EventBus.publish('error', "Please set item description");
      }

      props.setLoader({message:"Voting creating...",status:true});
      props.setVoting({title,description,status,data:exchanges})
  };

  const handleImageChange = (event,index) => {
    const { name, files } = event.target;
    const list = [...state.exchanges];
    if (!files[0]) return;
    const reader = new FileReader();

    reader.onload = e => {
      const imageUrl = e.target.result;
      list[index][name] = files[0];
      list[index]["previewImage"] = imageUrl;
      setState({exchanges:list});
    };

    reader.readAsDataURL(files[0]);
  };

  const addInputField = ()=>{
    let {exchanges} = state;
    setState({exchanges:[...exchanges, { 
      image:"",
      previewImage:"",
      title:"",
      description:""
    }]})
  }

  const removeFormFields = (index)=>{
    let {exchanges} = state;
    const rows = [...exchanges];
    rows.splice(index, 1);
    setState({exchanges:rows})
  }

  const handleChangeForm = (evnt,index)=>{
    let {exchanges} = state;
    const { name, value } = evnt.target;
    const list = [...exchanges];
    list[index][name] = value;
    setState({exchanges:list})
  }

  useEffect(()=>{
    if(props.getVoting.length > 0){
      setState({exchanges: [{ 
        image:"",
        previewImage:"",
        title:"",
        description:""
      },{ 
        image:"",
        previewImage:"",
        title:"",
        description:""
      }]})
      setStatus("Active");
      setTitle();
      setDescription();
    }
  },[props.getVoting])

  return (
    <div className='content'>
      <h1>Add New Voting Pole</h1>
      <div className='main-container mintedNFT'>
        <Fragment>
          <div className='add-voting-page'>
          <div className='row'>
            <div className='col-12'>
              <ValidatorForm className='form'>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label htmlFor='PoleTitle'>Pole Title</label>
                    <TextField
                      id='PoleTitle'
                      name='PoleTitle'
                      className='form-control'
                      placeholder='Pole title'
                      value={title}
                      onChange={e=>setTitle(e.target.value)}
                      validators={['required']}
                      errorMessages={['Pole Title is required']}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='Description'>Description</label>
                    <TextField
                      id='Description'
                      name='Description'
                      multiline
                      className='form-control'
                      placeholder='Pole Description'
                      value={description}
                      onChange={e=>setDescription(e.target.value)}
                      validators={['required']}
                      errorMessages={['Pole Description is required']}
                    />
                  </div>
                  <div className='form-group'>
                      <label htmlFor='category'>Status</label>
                      <Autocomplete
                            value={status}
                            onChange={(event, newValue) => {setStatus(newValue)}}
                            id='controllable-states-demo'
                            options={['Inactive', 'Active']}
                            sx={{ width: 300 }}
                            renderInput={params => <TextField {...params} />}
                          />
                  </div>
                  <hr></hr>
                  <div className='col-12'>
                    <div className='form-group text-right submit-group'>
                      <button className='btn-style-one' onClick={addInputField}>Add Items</button>
                    </div>
                  </div>
                  {state.exchanges.map((data, index)=>{
                    const {title,description,previewImage}= data;
                    return(
                        <div key={index}>
                          <div className='form-group'>
                            <div className='img-upload-area'>
                              <div className='img-box'>
                                {previewImage &&
                                  <img
                                    src={previewImage}
                                    alt=''
                                  />
                                }
                              </div>
                              <div className='text-box'>
                                <i className='icon'><img src={require('../../assets/img/upload.png')} alt=''/></i>
                                <p>Drag and drop or Browse your image file<br /> (png and jpg file only)</p>
                                <div className='input-file'>
                                  <input
                                    type='file'
                                    id='image'
                                    name='image'
                                    accept='image/*'
                                    onChange={(e)=>handleImageChange(e,index)}
                                    className='form-control'
                                    // required={!Boolean(state.selectedRack)}
                                  />
                                  <span className='add-btn'>Select Image</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='name'>Title</label>
                            <TextField
                              id='Title'
                              name='title'
                              value={title || ""}
                              className='form-control'
                              placeholder='Enter title'
                              onChange={(e)=>handleChangeForm(e,index)}
                              validators={['required']}
                              errorMessages={['Title is required']}
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='name'>Description</label>
                            <TextField
                              id='Description'
                              name='description'
                              value={description || ""}
                              className='form-control'
                              placeholder='Enter description'
                              multiline
                              onChange={(e)=>handleChangeForm(e,index)}
                              validators={['required']}
                              errorMessages={['Description is required']}
                            />
                          </div> 
                          <div className='form-group'>
                          <div className='form-group text-right submit-group'>
                          {(state.exchanges.length!==2) ? <button className='btn-style-one'  onClick={()=>removeFormFields(index)}>Remove Item</button>:""}
                          </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className='col-12'>
                  <div className='form-group text-center submit-group'>
                  {props.isLoader.status
                    ? <button className='btn-style-one'><i className="fa fa-spinner fa-spin fa-fw"></i></button>
                    : <button className='btn-style-one' onClick={handleSubmit}>Save</button>
                  }
                  </div>
                </div>
              </div>
            </ValidatorForm>
            </div>
          </div>
          </div>
        </Fragment>
      </div>


    </div>
  );
};


const mapDispatchToProps = {setVoting,getAllVoting,setLoader};

const mapStateToProps = ({ Auth }) => {
  let {getVoting,isLoader} = Auth;
  return {getVoting,isLoader};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVoting);
