import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Fragment, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './index.css';
import { addMedia, setLoader, getMediaItems, deleteMediaItem, updateMediaImage, updateMediaDetails } from '../../store/actions/Auth';

const alternate = require('../../assets/img/place-img.png');

const Media = props => {

  const [state, setState] = useState({
    formData: {
      name: '',
      description: '',
      image: null,
    },
  });

  let [name, setName] = useState("");
  let [banner, setBanner] = useState("");
  let [mediaType, setMediaType] = useState("");
  let [formImage, setFormImage] = useState("");
  let [description, setDescription] = useState("");

  let [editData, setEditData] = useState({});
  let [mediaData, setMediaData] = useState([]);
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const toggle = () => setModalIsOpen(!modalIsOpen);
  const toggle2 = () => setEditModalIsOpen(!editModalIsOpen);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true)
  }

  const setEditModalIsOpenToTrue = (item) => {
    setEditData(item);
    setEditModalIsOpen(true);
  }

  useEffect(() => {
    props.setLoader({message:"Fetching Data...",status:true});
    props.getMediaItems()
  }, []);

  useEffect(() => {
    if (props.mediaItems && props.mediaItems.length > 0) {
      setMediaData(props.mediaItems);
    }
  }, [props.mediaItems]);

  const handleImageError = (idx) => {
    mediaData[idx]['image'] = alternate;
    setMediaData([...mediaData]);
  };

  const handleEditImageError = () => {
    editData['image'] = alternate;
    setEditData([...editData]);
  };

  async function handleImageChange(event) {
    setBanner(event.target.files[0]);
    let parts = event.target.files[0]['type'].split('/');
    let mediaTyp = parts[0];
    setMediaType(mediaTyp);
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = e => {
      const imageUrl = e.target.result;
      setFormImage(imageUrl)
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          image: file
        }
      }));
    };

    reader.readAsDataURL(file);
  };

  async function handleChange(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "name") {
      setName(e.target.value);
    } else if ([e.target.name] == "description") {
      setDescription(e.target.value);
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      if (name == "") {
        EventBus.publish("error", `Please enter media title`);
        return;
      }

      if (!name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter media title`);
        return;
      }

      if (!name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Media title must contain alphabets`);
        return;
      }

      if (description == "") {
        EventBus.publish("error", `Please enter media description`);
        return;
      }

      if (!description.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter media description`);
        return;
      }

      if (!description.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Media description must contain alphabets`);
        return;
      }

      if (banner == "") {
        EventBus.publish("error", `Please upload media`);
        return;
      }

      if (banner == undefined) {
        EventBus.publish("error", `Please upload media`);
        return;
      }

      props.setLoader({
        message: "Uploading Media...",
        status: true,
      });


      let data = new FormData();
      data.append('name', name);
      data.append('description', description);
      data.append('status', "active");
      data.append('mediaType', mediaType);
      data.append('image', banner);
      props.addMedia(data);

      setName("");
      setDescription("");
      setFormImage("");
      setBanner("");
      setMediaType("");
      setModalIsOpen(false);
    } catch (e) {
      console.log(e);
      setModalIsOpen(false);
      props.setLoader({
        message: "Upload Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to upload!`);
    }
  };

  async function handleImageEdit(event) {
    setBanner(event.target.files[0]);
    let parts = event.target.files[0]['type'].split('/');
    let mediaTyp = parts[0];
    setMediaType(mediaTyp);
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = e => {
      const imageUrl = e.target.result;
      setFormImage(imageUrl)
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          image: file
        }
      }));
    };

    reader.readAsDataURL(file);

    props.setLoader({
      message: "Updating Media...",
      status: true,
    });
    let data = new FormData();
    data.append('mediaId', editData['_id']);
    data.append('mediaType', mediaTyp);
    data.append('image', event.target.files[0]);
    props.updateMediaImage(data);

    setEditModalIsOpen(false);
  };

  async function handleUpdate(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      if (name !== "") {
        if (!name.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter media title`);
          return;
        }

        if (!name.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Media title must contain alphabets`);
          return;
        }
      }

      if (description !== "") {
        if (!description.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter media description`);
          return;
        }

        if (!description.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Media description must contain alphabets`);
          return;
        }
      }

      props.setLoader({
        message: "Updating Media Details...",
        status: true,
      });

      props.updateMediaDetails({ mediaId: editData['_id'], name: name !== "" ? name : editData['name'], description: description !== "" ? description : editData['description'], status: "active" });

      setName("");
      setDescription("");
      setEditModalIsOpen(false);
    } catch (e) {
      setEditModalIsOpen(false);
      console.log(e);
      props.setLoader({
        message: "Update Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to update!`);
    }
  };

  async function handleDelete(item) {
    try {

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      props.setLoader({
        message: "Deleting Media...",
        status: true,
      });

      props.deleteMediaItem({ mediaId: item['_id'] });

    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Delete Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to delete!`);
    }
  };

  return (
    <div className='content'>
      <h1>Media</h1>
      <div className='main-container mintedNFT'>
        <div className='main-container-head mb-3'>
          <div className='btn-group'>
            {/* <button className='btn-style-two'>Active</button>
            <button className='btn-style-two'>Inactive</button> */}
          </div>
          <button className='btn-style-one' onClick={setModalIsOpenToTrue}>
            <i className='icon'><img src={require('../../assets/img/plus.svg')} alt='' /></i>
            Add New
          </button>
        </div>
        <Fragment>
          <div className='main-container-head mb-3'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Upload Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {mediaData.length > 0 &&
                    mediaData.map((item, idx) => (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                        </TableCell>
                        <TableCell>{item['name'] ? item['name'] : "-"}</TableCell>
                        <TableCell>{item['description'] ? item['description'] : "-"}</TableCell>
                        <TableCell>{item['updatedAt'] ? moment.utc(new Date(item['updatedAt']).getTime()).local().format("dddd, MMMM Do YYYY, h:mm:ss a") : "-/-/-"}</TableCell>
                        <TableCell>
                          <button className='editand-remove' onClick={() => setEditModalIsOpenToTrue(item)}>
                            <img src={require('../../assets/img/pencil.svg')} alt='' />
                          </button>
                          <button className='editand-remove' onClick={() => handleDelete(item)}>
                            <img src={require('../../assets/img/trash.svg')} alt='' />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fragment>
      </div>

      {/* ---------------ADD Media MODAL--------------- */}
      <Modal
        isOpen={modalIsOpen} toggle={toggle}
        className='main-modal add-media-modal'
      >
        <ModalHeader
          toggle={toggle}
          x
        >
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className='title-area'>
            <h2>Add Media</h2>
          </div>
          <ValidatorForm className='form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>

                <div className='form-group'>
                  <div className='img-upload-area'>
                    <div className='img-box'>
                      {
                        formImage !== "" &&
                        <img
                          src={formImage}
                          alt=''
                        />
                      }
                    </div>
                    <div className='text-box'>
                      <i className='icon'><img src={require('../../assets/img/upload.png')} alt='' /></i>
                      <p>Drag and drop or Browse your image file<br /> (media file only)</p>
                      <div className='input-file'>
                        <input
                          type='file'
                          id='image'
                          name='image'
                          accept='image/*,video/*'
                          onChange={handleImageChange}
                          className='form-control'
                          required={!Boolean(state.selectedRack)}
                        />
                        <span className='add-btn'>Select Image</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>Title</label>
                  <TextField
                    id='name'
                    name='name'
                    value={name}
                    className='form-control'
                    placeholder='Title'
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['Name is required']}
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>Description:</label>
                  <TextField
                    id='description'
                    name='description'
                    multiline
                    value={description}
                    className='form-control'
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['Description is required']}
                  />
                </div>
              </div>

              <div className='col-12'>
                <br />
                <div className='form-group text-center'>
                  <button className='btn-style-one' loading={props.isLoader}>Add</button>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </ModalBody>
      </Modal>

      {/* ---------------Edit Media MODAL--------------- */}
      <Modal
        isOpen={editModalIsOpen} toggle={toggle2}
        className='main-modal add-media-modal'
      >
        <ModalHeader
          toggle={toggle2}
          x
        >
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className='title-area'>
            <h2>Update Media</h2>
          </div>
          <ValidatorForm className='form' onSubmit={handleUpdate}>
            {
              Object.keys(editData).length > 0 &&
              <div className='row'>
                <div className='col-12'>

                  <div className='form-group'>
                    <div className='img-upload-area'>
                      <div className='img-box'>
                        {
                          editData['image'] &&
                          <img src={editData['image'] ? editData['image'] : formImage} onError={() => handleEditImageError()} alt="Alternate Image" />
                        }
                      </div>
                      <div className='text-box'>
                        <i className='icon'><img src={require('../../assets/img/upload.png')} alt='' /></i>
                        <p>Drag and drop or Browse your image file<br /> (media file only)</p>
                        <div className='input-file'>
                          <input
                            type='file'
                            id='image'
                            name='image'
                            accept='image/*,video/*'
                            onChange={handleImageEdit}
                            className='form-control'
                          />
                          <span className='add-btn'>Select Image</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='name'>Title</label>
                    <TextField
                      id='name'
                      name='name'
                      defaultValue={editData['name']}
                      className='form-control'
                      onChange={handleChange}
                      validators={['required']}
                      placeholder={editData['name'] ? editData['name'] : name}
                      errorMessages={['Name is required']}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='name'>Description:</label>
                    <TextField
                      id='description'
                      name='description'
                      multiline
                      defaultValue={editData['description']}
                      placeholder={editData['description'] ? editData['description'] : description}
                      className='form-control'
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['Description is required']}
                    />
                  </div>
                </div>

                <div className='col-12'>
                  <br />
                  <div className='form-group text-center'>
                    <button className='btn-style-one' loading={props.isLoader}>Update</button>
                  </div>
                </div>
              </div>
            }
          </ValidatorForm>
        </ModalBody>
      </Modal>

    </div>
  );
};

const mapDispatchToProps = {
  addMedia,
  setLoader,
  getMediaItems,
  deleteMediaItem,
  updateMediaImage,
  updateMediaDetails,
};

const mapStateToProps = ({ Auth }) => {
  let { mediaItems, isLoader } = Auth;
  return { mediaItems, isLoader };
};
export default connect(mapStateToProps, mapDispatchToProps)(Media);
