import moment from "moment";
import EventBus from "eventing-bus";
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './index.css';
import { setLoader, createSale, getSaleItems, deleteSaleItem, updateSaleImage, updateSaleDetails } from "../../store/actions/Auth";

const alternate = require('../../assets/img/place-img.png');

const Sales = props => {

  const [state, setState] = useState({
    formData: {
      name: '',
      description: '',
      image: null,
    },
  });

  let [name, setName] = useState("");
  let [price, setPrice] = useState("");
  let [banner, setBanner] = useState("");
  let [formImage, setFormImage] = useState("");
  let [description, setDescription] = useState("");
  let [status, setStatus] = useState("active");
  
  let [editData, setEditData] = useState({});
  let [saleData, setSaleData] = useState([]);
  let [SaleAddIsOpen, setSaleAddIsOpen] = useState(false);
  let [editModalIsOpen, setEditModalIsOpen] = useState(false);
  
  const toggle = () => setSaleAddIsOpen(!SaleAddIsOpen);
  const toggle2 = () => setEditModalIsOpen(!editModalIsOpen);

  const setSaleAddIsOpenToTrue = () => {
    setSaleAddIsOpen(true)
  }

  const setEditSaleModalIsOpenToTrue = (item) => {
    setEditData(item);
    setStatus(item['status']);
    setEditModalIsOpen(true);
  }

  useEffect(() => {
    props.setLoader({message:"Fetching Data...",status:true});
    props.getSaleItems()
  }, []);

  useEffect(() => {
    if (props.saleItems && props.saleItems.length > 0) {
      setSaleData(props.saleItems);
      setStatus("active");
    }
  }, [props.saleItems]);

  const handleImageError = (idx) => {
    saleData[idx]['image'] = alternate;
    setSaleData([...saleData]);
  };

  const handleEditImageError = () => {
    editData['image'] = alternate;
    setEditData([...editData]);
  };

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();

    setBanner(file);
    reader.onload = e => {
      const imageUrl = e.target.result;
      setFormImage(imageUrl)
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          image: file
        }
      }));
    };

    reader.readAsDataURL(file);
  };

  async function handleChange(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "name") {
      setName(e.target.value);
    } else if ([e.target.name] == "price") {
      setPrice(parseFloat(e.target.value));
    } else if ([e.target.name] == "description") {
      setDescription(e.target.value);
    } else if ([e.target.name] == "status") {
      editData['status'] = e.target.value;
      setStatus(e.target.value);
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      if (name == "") {
        EventBus.publish("error", `Please enter item title`);
        return;
      }

      if (!name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter item title`);
        return;
      }

      if (!name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Item title must contain alphabets`);
        return;
      }

      if (description == "") {
        EventBus.publish("error", `Please enter item description`);
        return;
      }

      if (!description.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter item description`);
        return;
      }

      if (!description.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Item description must contain alphabets`);
        return;
      }

      if (price == "") {
        EventBus.publish("error", `Please enter item price`);
        return;
      }

      if (price == 0) {
        EventBus.publish("error", `Item price cannot be 0`);
        return;
      }

      if (banner == "") {
        EventBus.publish("error", `Please upload item image`);
        return;
      }

      if (banner == undefined) {
        EventBus.publish("error", `Please upload item image`);
        return;
      }

      props.setLoader({
        message: "Uploading sale item...",
        status: true,
      });


      let data = new FormData();
      data.append('name', name);
      data.append('description', description);
      data.append('price', price);
      data.append('image', banner);
      data.append('status', status);
      props.createSale(data);

      setName("");
      setPrice("");
      setDescription("");
      setFormImage("");
      setBanner("");
      setSaleAddIsOpen(false);
      props.getSaleItems();
    } catch (e) {
      console.log(e);
      setSaleAddIsOpen(false);
      props.setLoader({
        message: "Upload Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to upload!`);
    }
  };

  async function handleImageEdit(event) {
    setBanner(event.target.files[0]);
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = e => {
      const imageUrl = e.target.result;
      setFormImage(imageUrl)
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          image: file
        }
      }));
    };

    reader.readAsDataURL(file);

    props.setLoader({
      message: "Updating Media...",
      status: true,
    });
    let data = new FormData();
    data.append('saleId', editData['_id']);
    data.append('image', event.target.files[0]);
    props.updateSaleImage(data);

    setEditModalIsOpen(false);

    props.getSaleItems();
  };

  async function handleUpdate(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      if (name !== "") {
        if (!name.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter item title`);
          return;
        }

        if (!name.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Item title must contain alphabets`);
          return;
        }
      }

      if (description !== "") {
        if (!description.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter item description`);
          return;
        }

        if (!description.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Item description must contain alphabets`);
          return;
        }
      }

      if (price !== "") {
        if (price == 0) {
          EventBus.publish("error", `Item price cannot be 0`);
          return;
        }
      }


      props.setLoader({
        message: "Updating Item Details...",
        status: true,
      });

      props.updateSaleDetails({ status: status ? status : editData['status'], saleId: editData['_id'], name: name !== "" ? name : editData['name'], description: description !== "" ? description : editData['description'], price: price !== "" ? price : editData['price'] });

      setName("");
      setPrice("");
      setDescription("");
      setEditModalIsOpen(false);
      props.getSaleItems();
    } catch (e) {
      setEditModalIsOpen(false);
      console.log(e);
      props.setLoader({
        message: "Update Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to update!`);
    }
  };

  async function handleDelete(item) {
    try {

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      props.setLoader({
        message: "Deleting Item...",
        status: true,
      });

      props.deleteSaleItem({ saleId: item['_id'] });
      props.getSaleItems();

    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Delete Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable to delete!`);
    }
  };


  return (
    <div className='content'>
      <h1>Sales</h1>
      <div className='main-container mintedNFT'>
        <div className='main-container-head mb-3'>
          <p className='main-container-heading'>Recent Sales  List</p>
          <button className='btn-style-one' onClick={() => setSaleAddIsOpenToTrue()}>
            <i className='icon'><img src={require('../../assets/img/plus.svg')} alt='' /></i>
            Add New
          </button>
        </div>
        <Fragment>
          <div className='main-container-head mb-3'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Upload Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleData.length > 0 &&
                    saleData.map((item, idx) => (
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                        </TableCell>
                        <TableCell>{item['name'] ? item['name'] : "-"}</TableCell>
                        <TableCell>{item['description'] ? item['description'] : "-"}</TableCell>
                        <TableCell style={{ fontWeight: '600' }}>{item['price'] ? item['price'] : "-"}</TableCell>
                        <TableCell component="th" scope="row">{item['updatedAt'] ? moment.utc(new Date(item['updatedAt']).getTime()).local().format("dddd, MMMM Do YYYY, h:mm:ss a") : "-/-/-"}</TableCell>
                        <TableCell>
                          <button className='editand-remove' onClick={() => setEditSaleModalIsOpenToTrue(item)}>
                            <img src={require('../../assets/img/pencil.svg')} alt='' />
                          </button>
                          <button className='editand-remove' onClick={() => handleDelete(item)}>
                            <img src={require('../../assets/img/trash.svg')} alt='' />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fragment>
      </div>

      {/* ---------------Add Sale MODAL--------------- */}
      <Modal
        isOpen={SaleAddIsOpen} toggle={toggle}
        className='main-modal add-media-modal'
      >
        <ModalHeader
          toggle={toggle}
          x
        >
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className='title-area'>
            <h2>Add Sale</h2>
          </div>
          <ValidatorForm className='form' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>

                <div className='form-group'>
                  <div className='img-upload-area'>
                    <div className='img-box'>
                      {
                        formImage !== "" &&
                        <img
                          src={formImage}
                          alt=''
                        />
                      }
                    </div>
                    <div className='text-box'>
                      <i className='icon'><img src={require('../../assets/img/upload.png')} alt='' /></i>
                      <p>Drag and drop or Browse your image file<br /> (png and jpg file only)</p>
                      <div className='input-file'>
                        <input
                          type='file'
                          id='image'
                          name='image'
                          accept='image/*,video/*'
                          onChange={handleImageChange}
                          className='form-control'
                          required={!Boolean(state.selectedRack)}
                        />
                        <span className='add-btn'>Select Image</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>Item Name</label>
                  <TextField
                    id='name'
                    name='name'
                    value={name}
                    className='form-control'
                    placeholder='Title'
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['Name is required']}
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>Price</label>
                  <TextField
                    id='price'
                    type="number"
                    name='price'
                    value={price}
                    className='form-control'
                    placeholder='Price in HKD'
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['Price is required']}
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>Description:</label>
                  <TextField
                    id='description'
                    name='description'
                    multiline
                    value={description}
                    className='form-control'
                    onChange={handleChange}
                    validators={['required']}
                    errorMessages={['Description is required']}
                  />
                </div>
               <div className='form-group'>
                  <label htmlFor='name'>Status:</label>
                  {/* <div className='form-control'> */}
                    <label>
                      <input
                        type='radio'
                        name='status'
                        value='active'
                        checked={status === 'active'}
                        onChange={handleChange}
                      />
                      Active
                    </label>
                    <label>
                      <input
                        type='radio'
                        name='status'
                        value='inactive'
                        checked={status === 'inactive'}
                        onChange={handleChange}
                      />
                      Inactive
                    </label>
                  {/* </div> */}
                </div>
              </div>
              <div className='col-12'>

                <div className='form-group text-center btn-group'>
                  <button className='btn-style-one' loading={props.isLoader}>Add</button>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </ModalBody>
      </Modal>

      {/* ---------------Edit Sale MODAL--------------- */}
      <Modal
        isOpen={editModalIsOpen} toggle={toggle2}
        className='main-modal add-media-modal'
      >
        <ModalHeader
          toggle={toggle2}
          x
        >
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className='title-area'>
            <h2>Edit Sale</h2>
          </div>
          {
            Object.keys(editData).length > 0 &&
            <ValidatorForm className='form' onSubmit={handleUpdate}>
              <div className='row'>
                <div className='col-12'>

                  <div className='form-group'>
                    <div className='img-upload-area'>
                      <div className='img-box'>
                        {
                          editData['image'] &&
                          <img src={editData['image'] ? editData['image'] : formImage} onError={() => handleEditImageError()} alt="Alternate Image" />
                        }
                      </div>
                      <div className='text-box'>
                        <i className='icon'><img src={require('../../assets/img/upload.png')} alt='' /></i>
                        <p>Drag and drop or Browse your image file<br /> (media file only)</p>
                        <div className='input-file'>
                          <input
                            type='file'
                            id='image'
                            name='image'
                            accept='image/*,video/*'
                            onChange={handleImageEdit}
                            className='form-control'
                          />
                          <span className='add-btn'>Select Image</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='name'>Item Name</label>
                    <TextField
                      id='name'
                      name='name'
                      defaultValue={editData['name']}
                      className='form-control'
                      onChange={handleChange}
                      validators={['required']}
                      placeholder={editData['name'] ? editData['name'] : name}
                      errorMessages={['Name is required']}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='name'>Price</label>
                    <TextField
                      id='price'
                      type="number"
                      name='price'
                      defaultValue={editData['price']}
                      className='form-control'
                      onChange={handleChange}
                      validators={['required']}
                      placeholder={editData['price'] ? editData['price'] : price}
                      errorMessages={['Price is required']}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='name'>Description:</label>
                    <TextField
                      id='description'
                      name='description'
                      multiline
                      defaultValue={editData['description']}
                      placeholder={editData['description'] ? editData['description'] : description}
                      className='form-control'
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['Description is required']}
                    />
                  </div>

                <div className='form-group'>
                  <label htmlFor='name'>Status:</label>
                  {/* <div className='form-control'> */}
                    <label>
                      <input
                        type='radio'
                        name='status'
                        value='active'
                        checked={editData['status'] === 'active'}
                        onChange={handleChange}
                      />
                      Active
                    </label>
                    <label>
                      <input
                        type='radio'
                        name='status'
                        value='inactive'
                        checked={editData['status'] === 'inactive'}
                        onChange={handleChange}
                      />
                      Inactive
                    </label>
                  {/* </div> */}
                </div>

                </div>
                <div className='col-12'>

                  <div className='form-group text-center btn-group'>
                    <button className='btn-style-one' loading={props.isLoader}>Update</button>
                  </div>
                </div>
              </div>
            </ValidatorForm>
          }
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  setLoader,
  createSale,
  getSaleItems,
  deleteSaleItem,
  updateSaleImage,
  updateSaleDetails
};

const mapStateToProps = ({ Auth }) => {
  let { saleItems, isLoader } = Auth;
  return { saleItems, isLoader };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sales);
