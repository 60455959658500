import React, { Fragment,useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import {setVoting,getAllVoting,setLoader, deleteVoting} from "../../store/actions/Auth";

import './index.css';
const Rack = props => {

  useEffect(()=>{
    props.setLoader({message:"Fetching Data...",status:true});
    props.getAllVoting();
  },[]);

  const deleteItem =(votingId) => {
    props.setLoader({message:"Delete voting...",status:true});
    props.deleteVoting({votingId})
  }

  return (
    <div className='content'>
      <h1>Votings</h1>
      <div className='main-container mintedNFT'>
        <div className='main-container-head mb-3'>
          <div className='btn-group'>
          </div>
          <Link to={"addnewvoting"} className='btn-style-one'>
            <i className='icon'><img src={require('../../assets/img/plus.svg')} alt=''/></i>
            Add New
          </Link>
        </div>
        <Fragment>
          <div className='main-container-head mb-3'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Create Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.getVoting && props.getVoting.length > 0 && props.getVoting.map((item,index) =>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                    {index+1}
                    </TableCell>
                    <TableCell>{item['title']}</TableCell>
                    <TableCell>{item['description']}</TableCell>
                    <TableCell>{moment(item['createdAt']).format('LLLL')}</TableCell>
                    <TableCell>
                    {item['status'] == "Active" ?
                        <button className='succes-btn'>
                          Active
                        </button>
                      :
                        <button className='inactive-btn'>
                          Inactive
                        </button>
                    }
                    </TableCell>
                    <TableCell>
                        <Link to={`editvoting/${item['_id']}`} className='editand-remove'>
                          <img src={require('../../assets/img/pencil.svg')} alt=''/>
                        </Link>
                        <button onClick={()=>deleteItem(item['_id'])} className='editand-remove'>
                          <img src={require('../../assets/img/trash.svg')} alt=''/>
                        </button>
                    </TableCell>
                  </TableRow>  
                  )}                
              </TableBody>
            </Table>
          </TableContainer>
          </div>
        </Fragment>
      </div>

    </div>
  );
};

const mapDispatchToProps = {setVoting,getAllVoting,setLoader,deleteVoting};

const mapStateToProps = ({ Auth }) => {
  let {getVoting} = Auth;
  return {getVoting};
};
export default connect(mapStateToProps, mapDispatchToProps)(Rack);
