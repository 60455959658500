/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = data => ({
  type: 'SET_LOADER',
  payload: data
});

/*========== LOGIN ACTIONS ============= */

export const login = ({ data, history }) => ({
  type: 'LOGIN',
  payload: data,
  history
});

export const loginLoader = data => ({
  type: 'LOGIN_LOADER',
  payload: data
});

export const setLoginData = data => ({
  type: 'SET_LOGIN_DATA',
  payload: data
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = data => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== VOTING  ============= */

export const setVoting = data => ({
  type: 'SET_VOTING',
  payload: data
});

export const getAllVoting = data => ({
  type: 'GET_ALL_VOTING',
  payload: data
});

export const getVoting = data => ({
  type: 'GET_VOTING',
  payload: data
});

export const getSingleVoting = data => ({
  type: 'GET_SINGLE_VOTING',
  payload: data
});

export const getVotingsItems = data => ({
  type: 'GET_VOTING_ITEMS',
  payload: data
});

export const setVotingsItems = data => ({
  type: 'SET_VOTING_ITEMS',
  payload: data
});

export const setSingleVoting = data => ({
  type: 'SET_SINGLE_VOTING',
  payload: data
});

export const updateVoting = data => ({
  type: 'UPDATE_VOTING',
  payload: data
});

export const updateVotingItems = data => ({
  type: 'UPDATE_VOTING_ITEMS',
  payload: data
});

export const updateVotingItemImage = data => ({
  type: 'UPDATE_VOTING_ITEMS_IMAGE',
  payload: data
});

export const deleteVoting = data => ({
  type: 'DELETE_VOTING',
  payload: data
});

export const getVotingChoices = data => ({
  type: 'GET_VOTING_CHOICES',
  payload: data
});

export const setVotingChoices = data => ({
  type: 'SET_VOTING_CHOICES',
  payload: data
});

/*========== MEDIA ACTIONS ============= */

export const addMedia = data => ({
  type: 'ADD_MEDIA',
  payload: data
});

export const updateMediaDetails = data => ({
  type: 'UPDATE_MEDIA_DETAILS',
  payload: data
});

export const updateMediaImage = data => ({
  type: 'UDPATE_MEDIA_IMAGE',
  payload: data
});

export const deleteMediaItem = data => ({
  type: 'DELETE_MEDIA_ITEM',
  payload: data
});

export const getMediaItems = () => ({
  type: 'GET_MEDIA_ITEMS'
});

export const setMediaItems = data => ({
  type: 'SET_MEDIA_ITEMS',
  payload: data
});

/*========== SALE ACTIONS ============= */

export const createSale = data => ({
  type: 'CREATE_SALE',
  payload: data
});

export const updateSaleDetails = data => ({
  type: 'UPDATE_SALE_DETAILS',
  payload: data
});

export const updateSaleImage = data => ({
  type: 'UDPATE_SALE_IMAGE',
  payload: data
});

export const deleteSaleItem = data => ({
  type: 'DELETE_SALE_ITEM',
  payload: data
});

export const getSaleItems = () => ({
  type: 'GET_SALE_ITEMS'
});

export const setSaleItems = data => ({
  type: 'SET_SALE_ITEMS',
  payload: data
});

export const getSoldItems = () => ({
  type: 'GET_SOLD_ITEMS'
});

export const setSoldItems = data => ({
  type: 'SET_SOLD_ITEMS',
  payload: data
});

/*========== EVENT SALE ACTIONS ============= */

export const createEventSale = data => ({
  type: 'CREATE_EVENT_SALE',
  payload: data
});

export const updateEventSaleDetails = data => ({
  type: 'UPDATE_EVENT_SALE_DETAILS',
  payload: data
});

export const updateEventSaleImage = data => ({
  type: 'UDPATE_EVENT_SALE_IMAGE',
  payload: data
});

export const deleteEventSaleItem = data => ({
  type: 'DELETE_EVENT_SALE_ITEM',
  payload: data
});

export const getEventSaleItems = () => ({
  type: 'GET_EVENT_SALE_ITEMS'
});

export const setEventSaleItems = data => ({
  type: 'SET_EVENT_SALE_ITEMS',
  payload: data
});