import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setToken } from "../axios";
import { setLoader, setLoginData, getVoting, setSingleVoting, setVotingsItems, setMediaItems, setSaleItems, setSoldItems, setVotingChoices, setEventSaleItems } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, {
    path: '/admin/loginAdmin',
    payload
  });


  if (error) EventBus.publish('error-login', error['response'] && error['response']['data']['message']);
  else if (response) {
    const decoded = jwt_decode(response['data']['body']['token']);
    if (!['admin', 'super-admin'].includes(decoded['role'])) {
      EventBus.publish(
        'error',
        "Can't login through other account. please use admin account"
      );
      return;
    }
    setToken(response['data']['body']['token']);
    yield put(setLoader({ status: false }));
    yield put(
      setLoginData({ token: response['data']['body']['token'] })
    );
    EventBus.publish('success', response['data']['message']);
    setTimeout(() => history.push('/home'), 1000);
    EventBus.publish(response['data']['message']);
    setTimeout(() => history.push('/home'), 200);
  }
  yield put(setLoader({ status: false }));
}


/*========== VOTING FUNCTIONS =============*/

function* setVoting({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/admin/addVoting', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else {
    yield put(getVoting(response['data']['body']));
    yield put(setLoader({ status: false }));
    EventBus.publish('success', response['data']['message']);
  }
}

function* getOrdersGraph() {
  const { error, response } = yield call(getCall, '/order/orders-graphs');
  if (error) EventBus.publish('error', error['message']['data']['message']);
  else {
    yield put(
      { type: 'SET_ORDERS_GRAPH', payload: response['data']['body'] }
    );
  }
}

function* getAllVoting() {
  const { error, response } = yield call(getCall, '/admin/getVotings');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { EventBus.publish('success', response['data']['message']); yield put(getVoting(response['data']['body'])); }
  yield put(setLoader({ status: false }));
}

function* getVotingChoices() {
  const { error, response } = yield call(getCall, '/admin/getVotingChoices');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else {
    // EventBus.publish('success', response['data']['message']);
    yield put(setVotingChoices(response['data']['body']));
  }
  yield put(setLoader({ status: false }));
}

function* getSingleVoting({ payload }) {
  const { error, response } = yield call(getCall, `/admin/getSingleVoting/${payload['_id']}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { EventBus.publish('success', response['data']['message']); yield put(setSingleVoting(response['data']['body'])); }
  yield put(setLoader({ status: false }));
}

function* updateVoting({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/admin/updateVotingDetail', payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { EventBus.publish('success', response['data']['message']); yield put(setSingleVoting(response['data']['body'])); }
  yield put(setLoader({ status: false }));
}

function* updateVotingItems({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/admin/updateVotingItemDetails', payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { yield put(setVotingsItems(response['data']['body'])); EventBus.publish('success', response['data']['message']); }
  yield put(setLoader({ status: false }));
}

function* updateVotingItemImage({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/admin/updateVotingItemImage', payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { yield put(setVotingsItems(response['data']['body'])); EventBus.publish('success', response['data']['message']); }
  yield put(setLoader({ status: false }));
}

function* getVotingItems({ payload, history }) {
  const { error, response } = yield call(getCall, `/admin/getVotingsItems/${payload['_id']}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { yield put(setVotingsItems(response['data']['body'])); EventBus.publish('success', response['data']['message']); }
  yield put(setLoader({ status: false }));
}

function* deleteVoting({ payload }) {
  const { error, response } = yield call(postCall, { path: '/admin/deleteVoting', payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else { yield put(getVoting(response['data']['body'])); EventBus.publish('success', response['data']['message']); }
  yield put(setLoader({ status: false }));
}


/*========== MEDIA FUNCTIONS =============*/

export function* getMediaItems() {
  const { error, response } = yield call(getCall, "/admin/getMediaItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setMediaItems(response['data']['body']));
  }
  yield put(setLoader(false));
};

export function* addMedia({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/addMedia", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_MEDIA_ITEMS",
    });
  }
};

export function* updateMediaDetails({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateMediaDetails", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_MEDIA_ITEMS",
    });
  }
};

export function* updateMediaImage({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateMediaImage", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_MEDIA_ITEMS",
    });
  }
};

export function* deleteMediaItem({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/deleteMediaItem", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_MEDIA_ITEMS",
    });
  }
};


/*========== SALE FUNCTIONS =============*/

export function* getSaleItems() {
  const { error, response } = yield call(getCall, "/admin/getSaleItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSaleItems(response['data']['body']));
  }
  yield put(setLoader(false));
};

export function* createSale({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/createSale", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_SALE_ITEMS",
    });
  }
};

export function* updateSaleDetails({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateSaleDetails", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_SALE_ITEMS",
    });
  }
};

export function* updateSaleImage({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateSaleImage", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_SALE_ITEMS",
    });
  }
};

export function* deleteSaleItem({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/deleteSaleItem", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_SALE_ITEMS",
    });
  }
};

export function* getSoldItems() {
  const { error, response } = yield call(getCall, "/admin/getSoldItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSoldItems(response['data']['body']));
  }
  yield put(setLoader(false));
};


/*========== EVENT SALE FUNCTIONS =============*/

export function* getEventSaleItems() {
  const { error, response } = yield call(getCall, "/admin/getEventSaleItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setEventSaleItems(response['data']['body']));
  }
  yield put(setLoader(false));
};

export function* createEventSale({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/createEventSale", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_EVENT_SALE_ITEMS",
    });
  }
};

export function* updateEventSaleDetails({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateEventSaleDetails", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_EVENT_SALE_ITEMS",
    });
  }
};

export function* updateEventSaleImage({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/updateEventSaleImage", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_EVENT_SALE_ITEMS",
    });
  }
};

export function* deleteEventSaleItem({ payload }) {
  const { error, response } = yield call(postCall, { path: "/admin/deleteEventSaleItem", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader(false));
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setLoader(false));
    yield put({
      type: "GET_EVENT_SALE_ITEMS",
    });
  }
};


function* actionWatcher() {

  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_ORDERS_GRAPH', getOrdersGraph);

  yield takeEvery('SET_VOTING', setVoting);
  yield takeEvery('UPDATE_VOTING', updateVoting);
  yield takeEvery('GET_ALL_VOTING', getAllVoting);
  yield takeEvery('GET_VOTING_ITEMS', getVotingItems);
  yield takeEvery('GET_SINGLE_VOTING', getSingleVoting);
  yield takeEvery('GET_VOTING_CHOICES', getVotingChoices);
  yield takeEvery('UPDATE_VOTING_ITEMS', updateVotingItems);
  yield takeEvery('UPDATE_VOTING_ITEMS_IMAGE', updateVotingItemImage);
  yield takeEvery('DELETE_VOTING', deleteVoting);

  yield takeEvery('ADD_MEDIA', addMedia);
  yield takeEvery('GET_MEDIA_ITEMS', getMediaItems);
  yield takeEvery('DELETE_MEDIA_ITEM', deleteMediaItem);
  yield takeEvery('UDPATE_MEDIA_IMAGE', updateMediaImage);
  yield takeEvery('UPDATE_MEDIA_DETAILS', updateMediaDetails);

  yield takeEvery('CREATE_SALE', createSale);
  yield takeEvery('GET_SALE_ITEMS', getSaleItems);
  yield takeEvery('GET_SOLD_ITEMS', getSoldItems);
  yield takeEvery('DELETE_SALE_ITEM', deleteSaleItem);
  yield takeEvery('UDPATE_SALE_IMAGE', updateSaleImage);
  yield takeEvery('UPDATE_SALE_DETAILS', updateSaleDetails);

  yield takeEvery('CREATE_EVENT_SALE', createEventSale);
  yield takeEvery('GET_EVENT_SALE_ITEMS', getEventSaleItems);
  yield takeEvery('DELETE_EVENT_SALE_ITEM', deleteEventSaleItem);
  yield takeEvery('UDPATE_EVENT_SALE_IMAGE', updateEventSaleImage);
  yield takeEvery('UPDATE_EVENT_SALE_DETAILS', updateEventSaleDetails);

}


export default function* rootSaga() {
  yield all([actionWatcher()]);
}


function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response && error.response.status === 401) EventBus.publish('tokenExpired');
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish('tokenExpired');
      return { error };
    });
}

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish('tokenExpired');
      return { error };
    });
}
