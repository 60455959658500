import React from 'react';
import { connect } from 'react-redux';
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";

import './index.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-left: 100%;
`;

const Loader = ({ isLoader }) => {
  return (
    <>
      {Boolean(isLoader['status']) === true &&
        <div className='loader'>
          <div className="loader-area">
            <BounceLoader
              css={override}
              size={50}
              color={'#5bf8fd'}
              loading={true}
            />
            <span className="loading-text">
              <p className="mt-4 text-white">{isLoader['message']}</p>
            </span>
          </div>
        </div>
      }
    </>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Auth }) => {
  let { isLoader } = Auth;
  return { isLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
